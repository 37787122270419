<template>
  <!-- 作品 -->
  <div class="kj_profile">
    <div
      class="current-nav centerText"
      v-if="Object.keys(gyData).length != 0"
      :style="{
        background: `url(${gyData.guanyuImg}) no-repeat`
      }"
    ></div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <ul class="kj_profile_nav">
      <li>
        <div class="li_text">
          {{ gyData.guanyu }}
        </div>
        <div class="li_con">
          <h2>{{ gyData.guanyuTitle }}</h2>
          <h4 v-html="gyData.guanyuText"></h4>
        </div>
      </li>
      <li>
        <div class="li_text">
          {{ jzgData.jzg }}
        </div>
        <div class="li_jzg">
          <div
            class="li_jzg_list"
            v-for="(item, idx) in jzgData.jzgList"
            :key="idx"
          >
            <h1>{{ item.key }}</h1>
            <div class="list_text" v-html="item.value"></div>
          </div>
        </div>
      </li>
      <li>
        <div class="li_text">
          {{ zzData.hzzz }}
        </div>
        <div class="li_con">
          <h2>{{ gyData.guanyuTitle }}</h2>
          <h4 v-html="gyData.guanyuText"></h4>
        </div>
      </li>
      <li>
        <div class="li_text">
          {{ khData.fwkh }}
        </div>
        <div class="li_con border">
          <img :src="khData.fwkhImg" alt="" style="width:100%">
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      gyData: {},
      jzgData: {},
      zzData: {},
      khData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.gyData = res.data.data.guanyu
          this.jzgData = res.data.data.jzg
          this.zzData = res.data.data.hzzz
          this.khData = res.data.data.fwkh
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_profile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .current-nav {
    background-size: 100% 100% !important;
  }
  .no_data {
    height: 400px;
  }
  .kj_profile_nav {
    line-height: 1.3;
    width: 80%;
    margin: 50px auto;
    li {
      margin-top: 90px;
      display: flex;
      .li_text {
        width: 200px;
        font-size: 28px;
        color: #333;
        line-height: 2;
      }
      .li_con,
      .li_jzg {
        flex: 1;
        width: calc(100% - 200px);
        color: #333;
        h2 {
          font-size: 40px;
        }
        h4 {
          line-height: 1.7;
          width: 50%;
          margin-top: 30px;
        }
      }
      .li_jzg {
        display: flex;
        flex-wrap: wrap;
        .li_jzg_list {
          width: 50%;
          margin-bottom: 40px;
          h1 {
            font-size: 40px;
          }
          .list_text {
            padding-right: 50px;
            margin-top: 15px;
            line-height: 1.6;
          }
        }
        .li_jzg_list:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }
      .border{
        border: 1px solid #ddd;
      }
    }
    li:nth-child(1) {
      margin-top: 0;
    }
  }
}
</style>
